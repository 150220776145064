<template>
  <div id="patient-info">
    <b-row>
      <b-col
        cols="2"
      >
        IMG
      </b-col>
      <b-col
        cols="10"
      >
        <b-row>
          <b-col>{{ fullName }}</b-col>
          <b-col>{{ nickName }}</b-col>
          <b-col>HN: {{ hn }}</b-col>
        </b-row>
        <b-row>
          <b-col>IDno: {{ IDNO }}</b-col>
          <b-col>{{ gender }}</b-col>
          <b-col>HN มหาราช: {{ mnHN }}</b-col>
        </b-row>
        <b-row>
          <b-col>Tel: {{ phone }}</b-col>
          <b-col>{{ email }}</b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        Address: {{ address }}
      </b-col>
      <b-col
        cols="6"
      >
        แพ้ยา: {{ allergicDrug }}
      </b-col>
      <b-col
        cols="6"
      >
        แพ้อาหาร: {{ allergyFood }}
      </b-col>
      <b-col
        cols="6"
      >
        รหัสพนักงาน: {{ staffID }}
      </b-col>
      <b-col
        cols="6"
      >
        สิทธิในการรักษา: {{ payorID }}
      </b-col>
    </b-row>
    <b-table
      class="mt-1"
      responsive="sm"
      :items="enrollLists"
    />
    <hr>
    <b-row>
      <b-col
        class="text-right"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          size="sm"
          @click="showTreatmentEnrollForm"
        >
          Add Treatment Enroll
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    hn: {
      type: String,
      default: () => '',
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      picture: null,
      fullName: '',
      nickName: '',
      IDNO: '',
      gender: '',
      phone: '',
      email: '',
      address: '',
      allergyFood: '',
      allergicDrug: '',
      mnHN: '',
      staffID: '',
      payorID: [],
      enrollLists: [
        {
          vn: 'xx', clinic: 'clinic xxx', doctor: 'doctor X', queue: 'xx',
        },
      ],
    }
  },
  methods: {
    showTreatmentEnrollForm() {
      // console.log('show Treatment Enroll Form')
      // If Registered
      this.$emit('ShowEnrollCreate', true)
    },
  },
}
</script>

<style>

</style>
