<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <select v-model="camera">
          <option>-- Select Device --</option>
          <option
            v-for="deviceOn in devices"
            :key="deviceOn.deviceId"
            :value="deviceOn.deviceId"
          >{{ deviceOn.label }}</option>
        </select>
      </div>
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-primary"
          @click="onCapture"
        >
          Capture Photo
        </button>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="w-1/2">
        <div class="border">
          <web-cam
            ref="webcam"
            :device-id="deviceId"
            width="100%"
            @started="onStarted"
            @stopped="onStopped"
            @error="onError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
          />
        </div>
      </div>
      <div class="w-1/2 ml-2">
        <h2>Captured Image</h2>
        <figure class="figure">
          <img
            :src="imagept"
            class="img-responsive"
          >
        </figure>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-2/3 w-full ml-auto">
        <vs-button
          class="mr-3 mb-2"
          @click="popupCamera=false"
        >
          ยืนยัน
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam'

export default {
  components: {
    WebCam,
  },
  data() {
    return {
      camera: null,
      deviceId: null,
      devices: [],
      imagept: '',
    }
  },
  computed: {
    device() {
      return this.devices.find(n => n.deviceId === this.deviceId)
    },
  },
  // device: function () {
  //   return this.devices.find(n => n.deviceId === this.deviceId)
  // },
  watch: {
    camera(id) {
      this.deviceId = id
    },
    devices() {
      // Once we have a list select the first one
      const [first] = this.devices
      if (first) {
        this.camera = first.deviceId
        this.deviceId = first.deviceId
      }
    },
  },
  methods: {
    onCapture() {
      // this.img = this.$refs.webcam.capture();
      // // console.log(this.$refs.webcam.capture())
      this.imagept = this.$refs.webcam.capture()
    },
    onStarted(stream) {
      // console.log('On Started Event', stream)
      setTimeout(() => {
        this.$emit('cameraStart')
      }, 2000)
    },
    onStopped(stream) {
      // console.log('On Stopped Event', stream)
    },
    onStop() {
      this.$refs.webcam.stop()
    },
    onStart() {
      this.$refs.webcam.start()
    },
    onError(error) {
      // console.log('On Error Event', error)
    },
    onCameras(cameras) {
      this.devices = cameras
      // // console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId
      this.camera = deviceId
      // // console.log("On Camera Change Event", deviceId);
    },
  },
}
</script>

<style>

</style>
