<template>
  <div id="treatment-enroll">
    <b-row>
      <b-col
        cols="2"
        offset="3"
      >
        <b-img
          thumbnail
          fluid
          :src="picture"
        />
      </b-col>
      <b-col
        cols="4"
      >
        <h4>{{ fullName }} ({{ nickName }})</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="4"
        offset="5"
      >
        <h4>{{ age }} {{ dob }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        offset="2"
        cols="3"
      >
        {{ email }}
      </b-col>
      <b-col
        cols="3"
      >
        {{ phone }}
      </b-col>
      <b-col
        cols="3"
      >
        {{ IDNO }}
      </b-col>
    </b-row>
    <b-row>
      <b-col
        offset="2"
        cols="3"
      >
        {{ nationality }}
      </b-col>
      <b-col
        cols="3"
      >
        {{ religion }}
      </b-col>
      <b-col
        cols="3"
      >
        {{ marital }}
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="6"
      >
        <label for="service">Detail from patient</label>
        <b-form-textarea
          id="detailsPatient"
          v-model="detailsPatient"
          placeholder="Detail from patient"
          rows="3"
        />
      </b-col>
      <b-col
        cols="6"
      >
        <b-row>
          <b-col
            cols="6"
          >
            <label for="clinic">Clinic</label>
            <v-select
              v-model="clinic"
              :options="clinicOptions"
            />
          </b-col>
          <b-col
            cols="6"
          >
            <label for="doctor">Doctor</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
            />
          </b-col>
          <b-col>
            <label for="treatmentDate">Period</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="6"
      >
        <b-row>
          <b-col
            cols="6"
          >
            <label for="payor">Payor</label>
            <v-select
              v-model="payor"
              :options="payorOptions"
            />
          </b-col>
          <b-col
            cols="6"
          >
            <label for="plan">Plan</label>
            <v-select
              v-model="plan"
              :options="planOptions"
            />
          </b-col>
          <b-col>
            <label for="service">Service</label>
            <v-select
              v-model="service"
              :options="serviceOptions"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="6"
      >
        <label for="service">Detail from MR</label>
        <b-form-textarea
          id="detailsMR"
          v-model="detailsMR"
          placeholder="Detail from MR"
          rows="3"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-1"
          size="sm"
        >
          close
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          size="sm"
          @click="enroll"
        >
          Register
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    hn: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      curdate: new Date(),
      picture: '',
      fullName: '',
      nickName: '',
      dob: '',
      age: '',
      IDNO: '',
      gender: '',
      phone: '',
      email: '',
      nationality: '',
      religion: '',
      marital: '',
      payorID: [],
      detailsPatient: '',
      clinic: '',
      doctor: '',
      treatmentDate: null,
      payor: '',
      plan: '',
      service: '',
      detailsMR: '',
      clinicOptions: [],
      doctorOptions: [],
      payorOptions: [],
      planOptions: [],
      serviceOptions: [],

    }
  },
  methods: {
    enroll() {
      // console.log('Enrolled')
      this.$swal({
        title: 'Enrollment Sucess.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('EnrollCreate', 'Enrolled', 'id')
        }
      })
    },
  },
}
</script>

<style>

</style>
