<template>
  <div id="new-patient">
    <b-form
      @submit.prevent="register"
    >
      <b-row>
        <b-col
          cols="2"
        >
          <input type="file" accept="image/*" capture="camera" />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="showCameraForm"
          >
            <feather-icon icon="CameraIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="10"
        >
          <b-row>
            <b-col
              cols="3"
            >
              <label for="titleEn">Title</label>
              <b-form-input
                id="titleEn"
                v-model="titleEn"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="firstNameEn">First name</label>
              <b-form-input
                id="firstNameEn"
                v-model="firstNameEn"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="middleNameEn">Middle Name</label>
              <b-form-input
                id="middleNameEn"
                v-model="middleNameEn"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="lastNameEn">Last Name</label>
              <b-form-input
                id="lastNameEn"
                v-model="lastNameEn"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="titleTh">คำนำหน้า</label>
              <b-form-input
                id="titleTh"
                v-model="titleTh"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="firstNameTh">ชื่อ</label>
              <b-form-input
                id="firstNameTh"
                v-model="firstNameTh"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="middleNameTh">ชื่อกลาง</label>
              <b-form-input
                id="middleNameTh"
                v-model="middleNameTh"
              />
            </b-col>
            <b-col
              cols="3"
            >
              <label for="lastNameTh">นามสกุล</label>
              <b-form-input
                id="lastNameTh"
                v-model="lastNameTh"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="passportIDno">Passport/IDNo.</label>
          <b-form-input
            id="passportIDno"
            v-model="passportIDno"
          />
        </b-col>
        <b-col>
          <label for="dob">วันเดือนปัเกิด</label>
          <b-form-datepicker
            id="dob"
            v-model="dob"
            :max="curdate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-col>
          <label for="gender">เพศ</label>
          <v-select
            v-model="gender"
            :options="genderOptions"
          />
        </b-col>
        <b-col>
          <label for="bloodGroup">กรุ๊ปเลือด</label>
          <v-select
            v-model="bloodGroup"
            :options="bloodGroupOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="nationality">สัญชาติ</label>
          <v-select
            v-model="nationality"
            :options="nationalityOptions"
          />
        </b-col>
        <b-col>
          <label for="religion">ศาสนา</label>
          <v-select
            v-model="religion"
            :options="religionOptions"
          />
        </b-col>
        <b-col>
          <label for="marital">สถานะภาพ</label>
          <v-select
            v-model="marital"
            :options="maritalOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="2"
        >
          <label for="addressOnCard">บ้านเลขที่/หมู่ที่</label>
          <b-form-input
            id="addressOnCard"
            v-model="addressOnCard"
          />
        </b-col>
        <b-col
          cols="10"
        >
          <label for="miCodeOnCard">ที่อยู่ตามทะเบียนบ้าน</label>
          <v-select
            v-model="miCodeOnCard"
            :options="addressOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="2"
        >
          <label for="addressCurrent">บ้านเลขที่/หมู่ที่</label>
          <b-form-input
            id="addressCurrent"
            v-model="addressCurrent"
          />
        </b-col>
        <b-col
          cols="8"
        >
          <label for="miCodeCurrent">ที่อยู่ปัจจุบัน</label>
          <v-select
            v-model="miCodeCurrent"
            :options="addressOptions"
          />
        </b-col>
        <b-col
          cols="2"
        >
          <label for="followAddressOnCard" />
          <b-form-checkbox
            v-model="followAddressOnCard"
            value="Y"
          >
            ตามทะเบียนบ้าน
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="7"
        >
          <b-row>
            <b-col>
              <label for="phone">โทรศัพท์</label>
              <b-form-input
                id="phone"
                v-model="phone"
              />
            </b-col>
            <b-col>
              <label for="email">อีเมล</label>
              <b-form-input
                id="email"
                v-model="email"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="congenitalDisease">โรคประจำตัว</label>
              <b-form-input
                id="congenitalDisease"
                v-model="congenitalDisease"
              />
            </b-col>
            <b-col>
              <label for="allergyFood">แพ้อาหาร</label>
              <b-form-input
                id="allergyFood"
                v-model="allergyFood"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="staffID">รหัสพนักงาน</label>
              <b-form-input
                id="staffID"
                v-model="staffID"
              />
            </b-col>
            <b-col>
              <label for="mnHN">HN มหาราช</label>
              <b-form-input
                id="mnHN"
                v-model="mnHN"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="5"
        >
          <label for="payorID">สิทธิในการรักษา</label>
          <v-select
            v-model="payorID"
            multiple
            :options="payorOptions"
          />
        </b-col>
      </b-row>
      <hr>
      <h6 class="text-danger">
        <u>กรณีฉุกเฉิน</u>
      </h6>
      <b-row>
        <b-col>
          <label for="emergencyName">ชื่อ-นามสกุล</label>
          <b-form-input
            id="emergencyName"
            v-model="emergencyName"
          />
        </b-col>
        <b-col>
          <label for="emergencyRelation">ความเกี่ยวข้อง</label>
          <b-form-input
            id="emergencyRelation"
            v-model="emergencyRelation"
          />
        </b-col>
        <b-col>
          <label for="emergencyPhone">โทรศัพท์</label>
          <b-form-input
            id="emergencyPhone"
            v-model="emergencyPhone"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
          >
            Reset
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
          >
            Register
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        id="cameraModal"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        title="Camera"
      >
        <form-camera-capture />
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FormCameraCapture from './FormCameraCapture.vue'

export default {
  components: {
    vSelect,
    FormCameraCapture,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      curdate: new Date(),
      picture: null,
      titleEn: '',
      firstNameEn: '',
      middleNameEn: '',
      lastNameEn: '',
      titleTh: '',
      firstNameTh: '',
      middleNameTh: '',
      lastNameTh: '',
      passportIDno: '',
      dob: '',
      gender: '',
      bloodGroup: '',
      nationality: '',
      religion: '',
      marital: '',
      addressCurrent: '',
      addressOnCard: '',
      followAddressOnCard: '',
      miCodeOnCard: '',
      miCodeCurrent: '',
      phone: '',
      email: '',
      payorID: [],
      congenitalDisease: '',
      allergyFood: '',
      allergicDrug: '',
      mnHN: '',
      hn: '',
      staffID: '',
      emergencyName: '',
      emergencyRelation: '',
      emergencyPhone: '',
      // Option for Select
      genderOptions: [
        { id: 'ชาย', label: 'ชาย' },
        { id: 'หญิง', label: 'หญิง' },
      ],
      bloodGroupOptions: [
        { id: 'A', label: 'A' },
        { id: 'B', label: 'B' },
        { id: 'AB', label: 'AB' },
        { id: 'O', label: 'O' },
        { id: 'N', label: 'ไม่ทราบ' },
      ],
      nationalityOptions: [],
      religionOptions: [],
      maritalOptions: [],
      addressOptions: [],
      payorOptions: [],
    }
  },
  methods: {
    showCameraForm() {
      this.$bvModal.show('cameraModal')
    },
    register() {
      // console.log('Register')
      // If Registered
      this.$emit('PatientCreate', 'Registered', 'id')
    },
  },
}
</script>

<style>

</style>
