<template>
  <div id="mr-home">
    <b-card
      no-body
    >
      <b-card-body>
        <b-tabs>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="AlertTriangleIcon" /> -->
              <span>Pre-Register</span>
            </template>
            <div>
              <hr>
              <pre-register-lists />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="AlertTriangleIcon" /> -->
              <span>Treatment Enroll</span>
            </template>
            <div>
              <hr>
              <enroll-lists />
            </div>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import PreRegisterLists from './pre-register/PreRegisterLists.vue'
import EnrollLists from './enroll/EnrollLists.vue'

export default {
  components: {
    PreRegisterLists,
    EnrollLists,
  },
}
</script>

<style>

</style>
