<template>
  <div id="enroll_lists">
    <b-row>
      <b-col
        md="6"
        sm="12"
        class="my-1"
      />
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-clicked="showEnrollForm"
        >
          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="6"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <!-- Modal Treatment Enroll -->
    <b-modal
      id="treatmentEnrollModal"
      hide-footer
      centered
      size="xl"
      title="Treatment Details"
    >
      <form-enroll
        :hn="hn"
        @EnrollCreate="onEnrollCreated"
      />
    </b-modal>
  </div>
</template>

<script>
import FormEnroll from '../components/FormEnroll.vue'

export default {
  components: {
    FormEnroll,
  },
  data() {
    return {
      hn: '',
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'enrollDate', label: 'Enroll Date', sortable: true },
        { key: 'enrollTime', label: 'Enroll Time', sortable: true },
        { key: 'hn', label: 'HN', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'nickname', label: 'Nickname', sortable: true },
        { key: 'gender', label: 'Gender', sortable: true },
        { key: 'birthDate', label: 'Birthdate', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        // {
        //   key: 'id', label: 'Id',
        // },
        // {
        //   key: 'avatar', label: 'Avatar',
        // },
        // { key: 'full_name', label: 'Full Name', sortable: true },
        // { key: 'post', label: 'Post', sortable: true },
        // 'email',
        // 'city',
        // 'start_date',
        // 'salary',
        // 'age',
        // 'experience',
        // { key: 'status', label: 'Status', sortable: true },
      ],
      items: [
        {
          id: 1,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 2,
        },
        {
          id: 2,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 2,
        },
        {
          id: 3,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 5,
        },
        {
          id: 4,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 2,
        },
        {
          id: 5,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 2,
        },
        {
          id: 6,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 1,
        },
        {
          id: 7,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 3,
        },
        {
          id: 8,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 3,
        },
        {
          id: 9,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 5,
        },
        {
          id: 10,
          enrollDate: '16 April 2021',
          enrollTime: '8:00 AM',
          hn: '0000007',
          name: 'Tony Stank',
          nickname: 'Tony',
          gender: 'Male',
          birthDate: '04 Sep 1999',
          status: 2,
        },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    // info(item, index, button) {
    //   this.infoModal.title = `Row index: ${index}`
    //   this.infoModal.content = JSON.stringify(item, null, 2)
    //   this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    // },
    // resetInfoModal() {
    //   this.infoModal.title = ''
    //   this.infoModal.content = ''
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showEnrollForm(record, index) {
      console.log(record, index)
      this.$bvModal.show('treatmentEnrollModal')
    },
    onEnrollCreated(enrolled, id) {
      console.log(enrolled, id)
      if (enrolled) {
        this.$bvModal.hide('treatmentEnrollModal')
      }
    },
  },
}
</script>
